import React from 'react';
import { PromotionBackgroundImage as SharedPromotionBackgroundImage } from '@vcc-package/promotions';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import PromotionBackgroundImageProps from './PromotionBackgroundImage.props';

const PromotionBackgroundImage: React.FC<PromotionBackgroundImageProps> = ({
  content: {
    heading1,
    heading2,
    description,
    image,
    tabletImage,
    mobileImage,
    cta,
    disclaimer,
    link,
    videos,
  },
  background = 'dark',
  sectionBackground,
  topInnerSpacing = 'none',
  bottomInnerSpacing = 'none',
  topOuterSpacing = 'xsmall',
  bottomOuterSpacing = 'xsmall',
  autoplay,
  loop,
  largePlayIcon,
  centeredPlayIcon,
  lazyLoadPoster,
  contentAlignOnDesktop,
  promoteImageOverTextOnMobile,
  disclaimerAlignment,
  sideMarginsOnContainer,
  contentWidthOnTablet,
  darkenVideo,
  ...props
}) => {
  return (
    <SectionContained
      {...props}
      background={sectionBackground}
      topInnerSpacing={topInnerSpacing}
      bottomInnerSpacing={bottomInnerSpacing}
      topOuterSpacing={topOuterSpacing}
      bottomOuterSpacing={bottomOuterSpacing}
    >
      <SharedPromotionBackgroundImage
        heading1={heading1}
        heading2={heading2}
        description={description}
        image={image}
        tabletImage={tabletImage}
        mobileImage={mobileImage}
        cta={cta}
        disclaimer={disclaimer}
        link={link}
        videos={videos}
        background={background}
        autoplay={autoplay}
        loop={loop}
        largePlayIcon={largePlayIcon}
        centeredPlayIcon={centeredPlayIcon}
        lazyLoadPoster={lazyLoadPoster}
        contentAlignOnDesktop={contentAlignOnDesktop}
        disclaimerAlignment={disclaimerAlignment}
        sideMarginsOnContainer={sideMarginsOnContainer}
        promoteImageOverTextOnMobile={promoteImageOverTextOnMobile}
        contentWidthOnTablet={contentWidthOnTablet}
        darkenVideo={darkenVideo}
      />
    </SectionContained>
  );
};

export default PromotionBackgroundImage;
