'use client';

import React, { useRef, useState } from 'react';
import {
  Block,
  type ExtendCSS,
  Flex,
  Text,
  ThemePicker,
  useTheme,
} from 'vcc-ui';
import { useBreakpoints } from '@vcc-www/hooks';
import { Button, Link } from '@vcc-www/buttons';
import { type backgroundColors } from '@vcc-www/page-section';
import { useResizeObserver } from '@volvo-cars/react-layout-utils';
import AnimatedReveal from '@vcc-www/animated-reveal';
import Markdown from '@vcc-www/markdown';
import USPreOrderHackForm, {
  isUsPreOrderUrl,
} from '@vcc-www/uspre-order-hack-form';
import Video, { hasVideoContent } from '@vcc-www/video';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import Disclaimer from '@vcc-www/disclaimer';
import { type PromotionBackgroundImageProps } from './PromotionBackgroundImage.props';
import { PopUpDisclaimer } from '@vcc-package/overlays';

type BackgroundColors = (typeof backgroundColors)[number];

const themeMap: Record<BackgroundColors, 'light' | 'dark'> = {
  white: 'light',
  light: 'light',
  dark: 'dark',
  black: 'dark',
};

const DEFAULT_HEIGHT = 488;
export const CONTENT_MAX_WIDTH = 350;

export const PromotionBackgroundImage: React.FC<
  React.PropsWithChildren<
    PromotionBackgroundImageProps & { useCtasAtTheTop?: boolean }
  >
> = ({
  heading1,
  heading2,
  description,
  image,
  tabletImage,
  mobileImage,
  cta,
  disclaimer,
  link,
  videos,
  background = 'dark',
  autoplay = false,
  loop = false,
  largePlayIcon = false,
  centeredPlayIcon = false,
  lazyLoadPoster = false,
  contentAlignOnDesktop = 'left',
  promoteImageOverTextOnMobile,
  disclaimerAlignment = 'center',
  sideMarginsOnContainer = true,
  contentWidthOnTablet = 'default',
  darkenVideo = true,
  useCtasAtTheTop,
  popUpDisclaimer,
  imageDensity,
}) => {
  const { onlyM, fromL } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const hasVideos = hasVideoContent({ videos });
  const textContentRef = useRef<HTMLDivElement>(null);
  const { baselineGrid } = useTheme();
  const defaultTextContentHeight = 23 * baselineGrid;
  const [additionalHeight, setAdditionalHeight] = useState(0);

  useResizeObserver({
    ref: textContentRef,
    onResize: ({ height = 0 }) => {
      setAdditionalHeight(height - defaultTextContentHeight);
    },
  });

  return (
    <>
      <AnimatedReveal>
        <ThemePicker variant={themeMap[background]}>
          <Block
            extend={containerCSS({
              sideMarginsOnContainer,
            })}
            data-color-mode={themeMap[background]}
          >
            {hasVideos ? (
              <Block
                extend={{
                  position: 'relative',
                  '& button': {
                    zIndex: 1,
                  },
                  '& video': {
                    height: DEFAULT_HEIGHT + additionalHeight,
                  },
                  '& > div': {
                    paddingBottom: DEFAULT_HEIGHT + additionalHeight,
                  },
                  extend: {
                    condition: darkenVideo,
                    style: {
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        background: 'black',
                        opacity: 0.5,
                      },
                    },
                  },
                }}
              >
                <Video
                  data-testid="PromotionBackgroundImage:video"
                  content={{ videos }}
                  autoplay={autoplay}
                  loop={loop}
                  largePlayIcon={largePlayIcon}
                  centeredPlayIcon={centeredPlayIcon}
                  lazyLoadPoster={lazyLoadPoster}
                />
              </Block>
            ) : (
              (image || tabletImage || mobileImage) && (
                <Image_DEPRECATED
                  sources={{
                    default: image?.src ?? '',
                    onlyM: tabletImage?.src,
                    onlyS: mobileImage?.src,
                  }}
                  aspectRatio={{
                    default: [21, 9],
                    onlyM: [4, 3],
                    onlyS: [2, 3],
                  }}
                  alt={
                    fromL
                      ? image?.alt || ''
                      : onlyM
                        ? tabletImage?.alt || ''
                        : mobileImage?.alt || ''
                  }
                  loading="native-lazy"
                  data-autoid="promotionBackgroundImage:image"
                  objectFit="cover"
                  imageDensity={imageDensity}
                  sizes={{ default: '100vw', fromL: '1232px' }}
                />
              )
            )}
            <Flex extend={wrapper(contentAlignOnDesktop)}>
              <Flex
                ref={textContentRef}
                extend={titleAndTextCSS(
                  promoteImageOverTextOnMobile,
                  contentWidthOnTablet,
                )}
              >
                {heading1 && (
                  <AnimatedReveal stagger={1}>
                    {popUpDisclaimer && popUpDisclaimer.description ? (
                      <PopUpDisclaimer
                        {...popUpDisclaimer}
                        trackLabel={`open PopUpDisclaimer | ${heading1}`}
                      >
                        {(icon) => (
                          <Text
                            as="h2"
                            variant="ootah"
                            subStyle="emphasis"
                            data-autoid="promotionBackgroundImage:title"
                            extend={titleCSS}
                          >
                            {heading1} {icon}
                          </Text>
                        )}
                      </PopUpDisclaimer>
                    ) : (
                      <Text
                        as="h2"
                        variant="ootah"
                        subStyle="emphasis"
                        data-autoid="promotionBackgroundImage:title"
                        extend={titleCSS}
                      >
                        {heading1}
                      </Text>
                    )}
                  </AnimatedReveal>
                )}
                {heading2 && (
                  <AnimatedReveal stagger={1}>
                    {!heading1 &&
                    popUpDisclaimer &&
                    popUpDisclaimer.description ? (
                      <PopUpDisclaimer
                        {...popUpDisclaimer}
                        trackLabel={`open PopUpDisclaimer | ${heading2}`}
                      >
                        {(icon) => (
                          <Text
                            as="h2"
                            variant="ootah"
                            subStyle="emphasis"
                            extend={titleCSS}
                          >
                            {heading2} {icon}
                          </Text>
                        )}
                      </PopUpDisclaimer>
                    ) : (
                      <Text
                        as="h2"
                        variant="ootah"
                        subStyle="emphasis"
                        extend={titleCSS}
                      >
                        {heading2}
                      </Text>
                    )}
                  </AnimatedReveal>
                )}
                {description && (
                  <AnimatedReveal stagger={2}>
                    <Text
                      as="div"
                      variant="columbus"
                      subStyle="emphasis"
                      extend={descriptionCSS}
                      data-autoid="promotionBackgroundImage:description"
                    >
                      <Markdown markdown={description} />
                    </Text>
                  </AnimatedReveal>
                )}
                {useCtasAtTheTop && !!(cta?.text && cta?.href) && (
                  <Flex extend={ctaAtTheTopCSS}>
                    <Button
                      intent="secondary"
                      href={cta.href}
                      target={cta.target}
                      data-autoid="promotionBackgroundImage:learnMore"
                      trackEventLabel={`${cta.text} | ${cta.href}`}
                      trackEventAction="button|click"
                      trackGA3={{ eventAction: 'click' }}
                    >
                      {cta.text}
                    </Button>
                  </Flex>
                )}
                {useCtasAtTheTop && !!(link?.text && link?.href) && (
                  <Link
                    href={link.href}
                    target={link.target}
                    data-autoid="promotionBackgroundImage:secondaryLink"
                    trackEventLabel={`${link.text} | ${link.href}`}
                    trackEventAction="button|click"
                    trackGA3={{ eventAction: 'click' }}
                  >
                    {link.text}
                  </Link>
                )}
              </Flex>
              {[cta, link].some((item) => item?.text && item?.href) && (
                <Flex extend={learnMoreLinkCSS(!!description)}>
                  {!!(cta?.text && cta?.href) && (
                    <AnimatedReveal stagger={3} rootMargin={50}>
                      <Flex extend={buttonStyleCSS}>
                        {isUsPreOrderUrl(cta.href) ? (
                          <USPreOrderHackForm action={cta.href}>
                            <Button
                              type="submit"
                              intent="secondary"
                              data-autoid="promotionBackgroundImage:cta"
                              trackEventLabel={`${cta.text} | ${cta.href}`}
                              trackEventAction="button|click"
                              trackGA3={{ eventAction: 'click' }}
                            >
                              {cta.text}
                            </Button>
                          </USPreOrderHackForm>
                        ) : (
                          !useCtasAtTheTop && (
                            <Button
                              intent="secondary"
                              href={cta.href}
                              target={cta.target}
                              data-autoid="promotionBackgroundImage:learnMore"
                              trackEventLabel={`${cta.text} | ${cta.href}`}
                              trackEventAction="button|click"
                              trackGA3={{ eventAction: 'click' }}
                            >
                              {cta.text}
                            </Button>
                          )
                        )}
                      </Flex>
                    </AnimatedReveal>
                  )}
                  {!!(link?.text && link?.href) && (
                    <AnimatedReveal stagger={3} rootMargin={50}>
                      <Flex extend={[buttonStyleCSS, linkStyleCSS]}>
                        {isUsPreOrderUrl(link.href) ? (
                          <USPreOrderHackForm action={link.href}>
                            <Link
                              type="submit"
                              data-autoid="promotionBackgroundImage:link"
                              trackEventLabel={`${link.text} | ${link.href}`}
                              trackEventAction="button|click"
                              trackGA3={{ eventAction: 'click' }}
                            >
                              {link.text}
                            </Link>
                          </USPreOrderHackForm>
                        ) : (
                          !useCtasAtTheTop && (
                            <Link
                              href={link.href}
                              target={link.target}
                              data-autoid="promotionBackgroundImage:secondaryLink"
                              trackEventLabel={`${link.text} | ${link.href}`}
                              trackEventAction="button|click"
                              trackGA3={{ eventAction: 'click' }}
                            >
                              {link.text}
                            </Link>
                          )
                        )}
                      </Flex>
                    </AnimatedReveal>
                  )}
                </Flex>
              )}
            </Flex>
          </Block>
        </ThemePicker>
      </AnimatedReveal>
      {!popUpDisclaimer && disclaimer && (
        <Disclaimer
          textExtend={disclaimerTextCSS(disclaimerAlignment)}
          extend={disclaimerCSS}
          text={disclaimer}
        />
      )}
    </>
  );
};

const containerCSS =
  ({
    sideMarginsOnContainer,
  }: {
    sideMarginsOnContainer: boolean;
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    position: 'relative',
    margin: 0,
    extend: {
      condition: sideMarginsOnContainer,
      style: {
        untilM: {
          margin: `0 ${baselineGrid * 3}px`,
        },
      },
    },
  });
const buttonStyleCSS: ExtendCSS = {
  width: 'max-content',
};

const linkStyleCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: baselineGrid,
});

const descriptionCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  paddingTop: 2 * baselineGrid,
  whiteSpace: 'pre-wrap',
  paddingRight: baselineGrid * 3,
  fromM: {
    paddingRight: 0,
  },
});

const wrapper =
  (
    contentAlignOnDesktop: PromotionBackgroundImageProps['contentAlignOnDesktop'],
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    position: 'absolute',
    top: 0,
    justifyContent: 'center',
    height: '100%',
    width: `calc(100% - ${baselineGrid * 3}px)`,
    left: baselineGrid * 3,
    untilL: {
      justifyContent: 'space-between',
    },
    fromM: {
      left: baselineGrid * 7.75,
      width: `calc(100% - ${baselineGrid * 7.75}px)`,
    },
    fromL: {
      bottom: baselineGrid * 19.75,
      ...(contentAlignOnDesktop === 'right'
        ? {
            right: baselineGrid * 26,
            left: 'auto',
            width: CONTENT_MAX_WIDTH,
          }
        : {
            width: `calc(100% - ${baselineGrid * 13}px)`,
            left: baselineGrid * 13,
          }),
    },
  });

const learnMoreLinkCSS =
  (hasDescription: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    position: 'absolute',
    ...(!hasDescription && { marginTop: baselineGrid * 2 }),
    untilM: {
      bottom: baselineGrid * 5,
    },
    fromM: {
      untilL: {
        bottom: baselineGrid * 8,
      },
    },
    fromL: {
      position: 'unset',
    },
  });

const titleAndTextCSS =
  (
    promoteImageOverTextOnMobile: boolean | undefined,
    contentWidthOnTablet: PromotionBackgroundImageProps['contentWidthOnTablet'],
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    position: 'absolute',
    pointerEvents: promoteImageOverTextOnMobile ? 'none' : 'auto',
    untilM: {
      top: baselineGrid * 5,
    },
    fromM: {
      untilL: {
        top: baselineGrid * 8,
        maxWidth: contentWidthOnTablet === 'wide' ? 420 : CONTENT_MAX_WIDTH,
      },
    },
    fromL: {
      position: 'unset',
      maxWidth: CONTENT_MAX_WIDTH,
    },
  });

const titleCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  onlyS: {
    paddingRight: 3 * baselineGrid,
  },
});

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `${4 * baselineGrid}px auto 0`,
  onlyS: {
    padding: `0 ${3 * baselineGrid}px`,
  },
});

const disclaimerTextCSS = (
  disclaimerAlignment: PromotionBackgroundImageProps['disclaimerAlignment'],
): ExtendCSS => ({
  onlyS: {
    textAlign: 'left',
  },
  fromM: {
    textAlign: disclaimerAlignment,
  },
});

const ctaAtTheTopCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `${baselineGrid * 4}px auto ${baselineGrid}px 0`,
});
