import React, { useEffect, useRef } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { PRE_ORDER_KEY } from './utils';

type Props = {
  action: string;
};

/**
 * # ⛔️ **ATTENTION** ⛔️
 *
 * This is a temporary hack that should only be used by US market fot their
 * pre-order links. This should be removed in favour of a better checkout link
 * solution that let's us use the regular links, in the near future.
 */
const USPreOrderHackForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  action,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const honeypotRef = useRef<HTMLInputElement>(null);
  const { origin } = useVolvoCarsUrl();
  const url = new URL(action, origin);

  url.searchParams.delete(PRE_ORDER_KEY);

  // We do a client side attempt to check the honeypot. If a bot trips in it but
  // does not run JavaScript, this obviously won't have any effect.
  useEffect(() => {
    formRef.current?.addEventListener('submit', (event) => {
      if (honeypotRef.current?.value) {
        event.preventDefault();
      }
    });
  }, []);

  return (
    <Block
      as="form"
      action={url.href}
      method="post"
      ref={formRef}
      extend={formCSS}
    >
      {/* Honeypot field */}
      <Block
        as="input"
        id="a_password"
        name="a_password"
        ref={honeypotRef}
        type="text"
        tabIndex={-1}
        autoComplete="off"
      />
      {children}
    </Block>
  );
};

export default USPreOrderHackForm;

const formCSS: ExtendCSS = {
  display: 'contents',
  '> input[type="text"]': {
    margin: '0 !important',
    padding: '0 !important',
    height: '0 !important',
    width: '0 !important',
    border: '0 !important',
    outline: '0 !important',
  },
};
